.Reminder {
    display: flex;
    justify-content: center;
    padding: 10px;
    border: 1px solid #27a68f;
    border-radius: 10px;
    margin-bottom: 20px;
}

.Reminder img {
    margin-right:30px;
}

.Reminder h2{
    padding-bottom: 10px;
}