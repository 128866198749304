.VideoPanel {
    background: #413f3f;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.VideoPanel__Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #27a68f;
    color: white;
    height: auto;
}

.VideoPanel__Header h3 {
    line-height: normal;
    color: white;
}

.VideoPanel__Header span p {
    line-height: normal;
    font-size: 12px;
}

.VideoPanel .VideoPanel__VideoList h2 {
    line-height: normal;
    color: white;
}

.VideoPanel .VideoPanel__ActiveUserPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.VideoPanel .VideoPanel__ActiveUserPanel .VideoPanel__EndCall {
    order: 0;
}

.VideoPanel .VideoPanel__ActiveUserPanel .VideoPanel__EndCall img {
    max-height: 60px;
    max-width: 60px;
}

.VideoPanel .VideoPanel__ActiveUserPanel .VideoPanel__ActiveUser {
    line-height: normal;
    text-align: center;
}

.VideoPanel .VideoPanel__ActiveUserPanel .VideoPanel__ActiveUser h2 {
    color: white;
}

.VideoPanel .VideoPanel__ActiveUserPanel .VideoPanel__ActiveUser video {
    width: 100%;
    height: 200px;
}

.VideoPanel .VideoPanel__VideoList .VideoPanel-item {
    width: 100%;
    height: auto;
    padding: 0;
}

.VideoPanel .VideoPanel__VideoList {
    width: 100%;
    height: 100vh;
    display: flex;
    flex:  0 0 70%;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .VideoPanel__Header span p {
        font-size: 8px;
    }

    .VideoPanel .VideoPanel__VideoList {
        height: 85vh;
        flex: 0 0 80%;
    }

    .VideoPanel .VideoPanel__ActiveUserPanel .VideoPanel__ActiveUser video {
        height: 100px;
    }

    .VideoPanel .VideoPanel__ActiveUserPanel {
        justify-content: center;
    }
 
}