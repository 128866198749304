.TSetPassword__Col__Header {
    background: #27a687;
    height: auto;
    line-height: 2em;
    padding: 20px;
    text-align: center;
}

.TSetPassword .TSetPassword__Col__Header h1 {
    color: white;
}

.TSetPassword .TSetPassword__Col__Form {
    padding-top: 20px;
    text-align: center;
}

.TSetPassword__Col__Form__Button {
    text-align: center;
}

.TSetPassword__Col__Form__Button button {
   width: 50%;
   background: #27a687;
   color: white;
}

.TSetPassword__Col__Form__Button button:hover, .TSetPassword__Col__Form__Button button:focus {
    background: #0A7360;
    color: white;
}