.BookingConfirmed {
    text-align: center;
    padding-top: 30px;
}

.BookingConfirmed h1:nth-child(2) {
    color: rgb(27, 136, 27);
}

.BookingConfirmed .BookingConfirmed__Info {
    display: flex;
    justify-content: center;
}

.BookingConfirmed .BookingConfirmed__Info img {
    margin-left: 30px;
}

.BookingConfirmed p {
    font-size: 25px;
    line-height: normal;
}
