.Sessions {
    text-align: center;
}

.Sessions .Sessions__Header {
    background: #27a68f;
    width: 400px;
    margin: 0 auto;
    height: auto;
}

.Sessions .Sessions__Header h1 {
    font-size: 20px;
    color: white;
    line-height: 1.5em;
   
}

.Sessions h2 {
    color: gray;
}